.searchPage__header {
    display: flex;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    align-items: flex-start;
    padding: 30px;
    border-bottom: 1px solid lightgray;
  }
  
  .searchPage__logo {
    object-fit: contain;
    height: 50px;
    margin-right: 50px;
  }
  
  .searchPage__headerBody > .search > .search__input {
    margin-top: 0;
    width: unset;
    margin: unset;
    max-width: unset;
  }
  
  .searchPage_options {
    display: flex;
    align-items: center;
    color: grey;
  }
  
  .searchPage_optionsLeft,
  .searchPage_optionsRight {
    display: flex;
  }
  
  .searchPage_optionsLeft a,
  .searchPage_optionsRight a {
    text-decoration: none;
    color: grey;
  }
  
  .searchPage_optionsRight {
    margin-left: 80px;
  }
  
  .searchPage_option {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .searchPage_option a {
    margin-left: 5px;
  }
  
  .searchPage__result {
    margin: 40px 0;
    border-bottom: 2px dotted grey;
  }
  
  .searchPage__resultCount {
    color: #70757a;
    font-size: 14px;
  }
  
  .searchPage__results {
    min-width: 350px;
    margin-top: 20px;        
    text-align: left;
  }
  
  .searchPage__resultTitle {
    text-decoration: none;
  }
  
  .searchPage__resultTitle:hover {
    text-decoration: underline;
  }
  
  .searchPage__resultTitle > h2 {
    font-weight: 500;
  }
  .searchPage__resultSnippet {
    margin-top: 10px;
  }
  
  .searchPage__resultImage {    
    width: 120px;
    object-fit: contain;
    margin-right: 10px;
  }
  