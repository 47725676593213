.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .home__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
  }
  
  .home__header a {
    margin-right: 20px;
    text-decoration: inherit;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
  }
  
  .home__header a:hover {
    text-decoration: underline;
  }
  
  .home__headerRight {
    display: flex;
    align-items: center;
    min-width: 13vw;
    justify-content: space-between;
  }
  
  .home__headerRight > .MuiSvgIcon-root {
    margin-right: 20px;
  }
  
  .home__body > img {
    object-fit: contain;
    height: 100px;
  }
  
  .home__body {
    flex: 1;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
  }
  